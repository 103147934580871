import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

export default function BasicTable(
  assetRefs: Array<any> | undefined,
  assetRefsGridApiRef: React.MutableRefObject<any>,
) {
  const emptyTableMessage = !assetRefs ? "Loading..." : "No asset refs found.";

  const columns: GridColDef[] = [
    { field: "id", headerName: "Asset Ref ID", width: 300 },
    { field: "RemoteRecipient", headerName: "Remote Recipient", width: 300 },
    { field: "Amount", headerName: "Amount", width: 100 },
  ];

  const rows = !assetRefs
    ? []
    : assetRefs!.map((assetRef) => ({
        id: assetRef.AssetRefId,
        RemoteRecipient: assetRef.RemoteRecipient,
        Amount: assetRef.refwNOKAmount,
      }));

  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        checkboxSelection
        disableRowSelectionOnClick
        disableMultipleRowSelection={true}
        slots={{
          noRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {emptyTableMessage}
            </Stack>
          ),
        }}
        apiRef={assetRefsGridApiRef}
      />
    </Box>
  );
}
