import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  useGetAllAccountBalancesQuery,
  useGetBankNameMappingQuery,
} from "../reducers/apiSlice";
import { transformAccountBalances } from "../utils/helpers";

export const AllAccountBalances = () => {
  const { data: accountBalances } = useGetAllAccountBalancesQuery(undefined, {
    pollingInterval: 7000,
  });
  const { data: bankNameMapping } = useGetBankNameMappingQuery();

  const transformedBalances = transformAccountBalances(
    accountBalances,
    bankNameMapping,
  );
  if (!transformedBalances) return <>Loading</>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Account Number</TableCell>
            <TableCell align="right">Balance wNOK</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transformedBalances.map((participant) => (
            <TableRow
              key={participant.AccountNo}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {participant.AccountNo}
              </TableCell>
              <TableCell align="right">{participant.Amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
