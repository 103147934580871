import { LineChart } from "@mui/x-charts/LineChart";
import dayjs, { Dayjs } from "dayjs";
import Box from "@mui/material/Box";
import { getSmoothedTimeSeries } from "../utils/helpers";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default function BasicLineChart(
  balances: Array<any> | undefined,
  tFrom: Dayjs | null,
  tTo: Dayjs | null,
  tToBalance: any | undefined,
) {
  if (!balances || !tFrom || !tTo || !tToBalance) return <>Loading</>;

  const smoothedBalances = getSmoothedTimeSeries(
    balances,
    tFrom,
    tTo,
    tToBalance,
  );

  var minBalance = 0;
  var maxBalance = 0;
  if (smoothedBalances.amounts.length > 0) {
    minBalance = Math.min(...smoothedBalances.amounts);
    maxBalance = Math.max(...smoothedBalances.amounts);
  }
  if (minBalance === maxBalance) {
    maxBalance = maxBalance + 10;
  }
  var diffBalance = maxBalance - minBalance;
  minBalance = Math.max(0, minBalance - 0.05 * diffBalance);
  maxBalance = maxBalance + 0.05 * diffBalance;

  return (
    <Box>
      <LineChart
        xAxis={[
          {
            data: smoothedBalances.times,
            scaleType: "time",
            min: tFrom.toDate(),
            max: tTo.toDate(),
          },
        ]}
        yAxis={[
          {
            min: minBalance,
            max: maxBalance,
          },
        ]}
        series={[
          {
            curve: "stepAfter",
            data: smoothedBalances.amounts,
          },
        ]}
        height={300}
      />
    </Box>
  );
}
