import * as React from "react";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "@mui/lab";
import dayjs, { Dayjs } from "dayjs";
import BalancesTable from "../components/BalancesTable";
import AccountStatisticsTable from "../components/MacroStatisticsTable";
import OwnTransactionsTable from "../components/OwnTransactionsTable";
import BasicLineChart from "../components/BalancesTimeline";
import HistoryRangePicker from "../components/HistoryRangePicker";
import {
  useGetOwnBalanceAtDateMutation,
  useGetOwnBalanceHistoryMutation,
  useGetTransactionHistoryMutation,
} from "../reducers/apiSlice";
import utc from "dayjs/plugin/utc";
import { AllParticipants } from "../components/AllParticipants";
import { Box } from "@mui/material";

dayjs.extend(utc);

const DashboardPage = () => {
  const [tFrom, setTFrom] = React.useState<Dayjs | null>(
    dayjs.utc().subtract(60, "minutes"),
  );
  const [tTo, setTTo] = React.useState<Dayjs | null>(dayjs.utc());
  const [currentTFrom, setCurrentTFrom] = React.useState<Dayjs | null>(
    dayjs.utc().subtract(60, "minutes"),
  );
  const [currentTTo, setCurrentTTo] = React.useState<Dayjs | null>(dayjs.utc());
  const [
    updateBalanceTimeRange,
    { isLoading: isLoadingUpdateBalanceTimeRange, data: balanceData },
  ] = useGetOwnBalanceHistoryMutation();
  const [
    updateBalanceAtTTo,
    { isLoading: isLoadingUpdateBalanceAtTTo, data: balanceAtTToData },
  ] = useGetOwnBalanceAtDateMutation();
  const [
    updateTransactionTimeRange,
    {
      isLoading: isLoadingUpdateTransactionTimeRange,
      isUninitialized: isUninitializedTransactionTimeRange,
      data: transactionData,
    },
  ] = useGetTransactionHistoryMutation();

  function handleTimeRangeUpdateClick() {
    if (tFrom && tTo) {
      updateBalanceTimeRange({ tFrom: tFrom.format(), tTo: tTo.format() });
      updateTransactionTimeRange({ tFrom: tFrom.format(), tTo: tTo.format() });
      updateBalanceAtTTo(tTo.format());
      setCurrentTFrom(tFrom);
      setCurrentTTo(tTo);
    }
  }

  if (isUninitializedTransactionTimeRange && tFrom && tTo) {
    updateBalanceTimeRange({ tFrom: tFrom.format(), tTo: tTo.format() });
    updateTransactionTimeRange({ tFrom: tFrom.format(), tTo: tTo.format() });
    updateBalanceAtTTo(tTo.format());
    setCurrentTFrom(tFrom);
    setCurrentTTo(tTo);
  }

  return (
    <div className="content-area">
      <Stack spacing={2} direction="row" width="100%">
        <Stack spacing={2} direction="column" width="100%">
          <div>
            <h2>Account</h2>
            {BalancesTable()}
          </div>
          <Stack spacing={2} direction="row" width="100%">
            <div style={{ width: "38%" }}>
              <h2>Network Participants</h2>
              {AllParticipants()}
            </div>
            <div style={{ width: "62%" }}>
              <h2>Statistics</h2>
              {AccountStatisticsTable()}
            </div>
          </Stack>
        </Stack>
      </Stack>
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #ccc",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      ></div>
      <Stack spacing={2} direction="column" width="100%">
        <div>
          <h1>Account History</h1>
          <div>
            <h2>Balance</h2>
            {BasicLineChart(
              balanceData?.balances,
              currentTFrom,
              currentTTo,
              balanceAtTToData,
            )}
            <Box
              boxShadow={1}
              bgcolor="background.paper"
              borderRadius={1}
              p={2}
            >
              <Stack
                spacing={2}
                direction="row"
                width="100%"
                alignItems="center"
              >
                {HistoryRangePicker(tFrom, setTFrom, tTo, setTTo)}
                <LoadingButton
                  loading={
                    isLoadingUpdateTransactionTimeRange ||
                    isLoadingUpdateBalanceTimeRange ||
                    isLoadingUpdateBalanceAtTTo
                  }
                  variant="text"
                  style={{ height: 40 }}
                  onClick={() => {
                    handleTimeRangeUpdateClick();
                  }}
                >
                  Update
                </LoadingButton>
              </Stack>
            </Box>
            <h2>Transactions</h2>
            {OwnTransactionsTable(transactionData?.transactions)}
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default DashboardPage;
