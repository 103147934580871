import * as React from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import dayjs, { Dayjs } from "dayjs";

export default function HistoryRangePicker(
  tFrom: Dayjs | null,
  setTFrom: React.Dispatch<React.SetStateAction<Dayjs | null>>,
  tTo: Dayjs | null,
  setTTo: React.Dispatch<React.SetStateAction<Dayjs | null>>,
) {
  function updateTFrom(newValue: Dayjs | null) {
    setTFrom(newValue);
    if (newValue === null) {
      setTTo(null);
    }
  }

  function updateTTo(newValue: Dayjs | null) {
    if (newValue != null && newValue > dayjs.utc()) {
      newValue = dayjs.utc();
    }
    setTTo(newValue);
    if (newValue === null) {
      setTFrom(null);
    }
  }

  return (
    <Grid container spacing={0} alignItems="center" justifyContent="center">
      <Grid
        item
        xs={1}
        style={{
          textAlign: "right",
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        }}
      >
        <Typography align="center" sx={{ padding: "8px" }}>
          {" "}
          From:{" "}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          textAlign: "left",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <DateTimePicker
          value={tFrom}
          onChange={(newValue) => updateTFrom(newValue)}
          timezone="system"
        />
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          textAlign: "right",
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
        }}
      >
        <Typography align="center" sx={{ padding: "8px" }}>
          {" "}
          To:{" "}
        </Typography>
      </Grid>
      <Grid
        item
        xs={4}
        style={{
          textAlign: "left",
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <DateTimePicker
          value={tTo}
          onChange={(newValue) => updateTTo(newValue)}
          timezone="system"
        />
      </Grid>
    </Grid>
  );
}
