import * as React from "react";
import Stack from "@mui/material/Stack";
import dayjs, { Dayjs } from "dayjs";
import { AllAccountBalances } from "../components/AllAccountBalances";
import utc from "dayjs/plugin/utc";
import AllTransactionsTable from "../components/AllTransactionsTable";
import {
  useGetOwnBalanceAtDateMutation,
  useGetTotalTransferVolumeHistoryMutation,
  useGetTotalWNOKHistoryMutation,
  useGetTransactionHistoryForAllParticipantsInNetworkMutation,
} from "../reducers/apiSlice";
import HistoryRangePicker from "../components/HistoryRangePicker";
import { LoadingButton } from "@mui/lab";
import BasicLineChart from "../components/BalancesTimeline";
import BasicVolumeChart from "../components/VolumeHistogram";
import { Box } from "@mui/material";

dayjs.extend(utc);

const OversightPage = () => {
  const [tFrom, setTFrom] = React.useState<Dayjs | null>(
    dayjs.utc().subtract(60, "minutes"),
  );
  const [tTo, setTTo] = React.useState<Dayjs | null>(dayjs.utc());
  const [currentTFrom, setCurrentTFrom] = React.useState<Dayjs | null>(
    dayjs.utc().subtract(60, "minutes"),
  );
  const [currentTTo, setCurrentTTo] = React.useState<Dayjs | null>(dayjs.utc());
  const [
    updateBalanceTimeRange,
    { isLoading: isLoadingUpdateBalanceTimeRange, data: balanceData },
  ] = useGetTotalWNOKHistoryMutation();
  const [
    updateVolumeTimeRange,
    { isLoading: isLoadingUpdateVolumeTimeRange, data: volumeData },
  ] = useGetTotalTransferVolumeHistoryMutation();
  const [
    updateBalanceAtTTo,
    { isLoading: isLoadingUpdateBalanceAtTTo, data: balanceAtTToData },
  ] = useGetOwnBalanceAtDateMutation();
  const [
    updateTransactionTimeRange,
    {
      isLoading: isLoadingUpdateTransactionTimeRange,
      isUninitialized: isUninitializedTransactionTimeRange,
      data: transactionData,
    },
  ] = useGetTransactionHistoryForAllParticipantsInNetworkMutation();

  function handleTimeRangeUpdateClick() {
    if (tFrom && tTo) {
      updateBalanceTimeRange({ tFrom: tFrom.format(), tTo: tTo.format() });
      updateVolumeTimeRange({ tFrom: tFrom.format(), tTo: tTo.format() });
      updateTransactionTimeRange({ tFrom: tFrom.format(), tTo: tTo.format() });
      updateBalanceAtTTo(tTo.format());
      setCurrentTFrom(tFrom);
      setCurrentTTo(tTo);
    }
  }

  if (isUninitializedTransactionTimeRange && tFrom && tTo) {
    updateBalanceTimeRange({ tFrom: tFrom.format(), tTo: tTo.format() });
    updateVolumeTimeRange({ tFrom: tFrom.format(), tTo: tTo.format() });
    updateTransactionTimeRange({ tFrom: tFrom.format(), tTo: tTo.format() });
    updateBalanceAtTTo(tTo.format());
    setCurrentTFrom(tFrom);
    setCurrentTTo(tTo);
  }

  return (
    <div className="content-area">
      <div style={{ width: "100%" }}>
        <h2>All Current Balances of Network Participants</h2>
        {AllAccountBalances()}
      </div>
      <div
        style={{
          width: "100%",
          borderBottom: "1px solid #ccc",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      ></div>
      <div style={{ width: "100%" }}>
        <h1>WNOK Macro Oversight</h1>
        <h2>History of Total WNOK in circulation</h2>
        {BasicLineChart(
          balanceData?.balances,
          currentTFrom,
          currentTTo,
          balanceAtTToData,
        )}
        <h2>Total WNOK Transfer Volume Histogram</h2>
        {BasicVolumeChart(volumeData?.balances, currentTFrom, currentTTo)}
        <Box boxShadow={1} bgcolor="background.paper" borderRadius={1} p={2}>
          <Stack spacing={2} direction="row" width="100%">
            {HistoryRangePicker(tFrom, setTFrom, tTo, setTTo)}
            <LoadingButton
              loading={
                isLoadingUpdateTransactionTimeRange ||
                isLoadingUpdateBalanceTimeRange ||
                isLoadingUpdateBalanceAtTTo ||
                isLoadingUpdateVolumeTimeRange
              }
              variant="text"
              onClick={() => {
                handleTimeRangeUpdateClick();
              }}
            >
              Update
            </LoadingButton>
          </Stack>
        </Box>
        <h2>All Transactions</h2>
        {AllTransactionsTable(transactionData?.transactions)}
      </div>
    </div>
  );
};

export default OversightPage;
