import * as React from "react";
import Stack from "@mui/material/Stack";
import TokenInputSlider from "../components/TokenInputSlider";
import BalancesTable from "../components/BalancesTable";
import OwnAssetRefsTable from "../components/OwnAssetRefsTable";
import {
  useGetOwnBalanceQuery,
  useEscrowWNOKMutation,
  useBridgeOutWNOKMutation,
  useGetAssetRefsMutation,
} from "../reducers/apiSlice";
import { useGridApiRef } from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";
import SelectNetworkDropdownMenu from "../components/SelectNetworkDropdownMenu";
import { Grid } from "@mui/material";
import { createAlerts } from "../utils/helpers";

type remoteRecipientAndNetwork = {
  recipient: string;
  network: string;
};

const BridgePage = () => {
  const [escrowRemoteRecipientAndNetwork, setEscrowRemoteRecipientAndNetwork] =
    React.useState<remoteRecipientAndNetwork>({ recipient: "", network: "" });
  const [escrowAmount, setEscrowAmount] = React.useState<number>(100);
  const [alertMsgs, setAlertMsgs] = React.useState<string[]>([]);
  const [severities, setSeverities] = React.useState<
    ("error" | "warning" | "info" | "success")[]
  >([]);
  let alerts = createAlerts(alertMsgs, severities);

  const [escrowWnok, { isLoading: isLoadingEscrowWnok }] =
    useEscrowWNOKMutation();
  const [bridgeOut, { isLoading: isLoadingBridgeOut }] =
    useBridgeOutWNOKMutation();
  const { data: ownBalance, refetch: refetchOwnBalance } =
    useGetOwnBalanceQuery();
  const [
    getAssetRefs,
    {
      isLoading: isLoadingGetAssetRefs,
      isUninitialized: isUninitializedAssetRefsTable,
      data: assetRefsData,
    },
  ] = useGetAssetRefsMutation();
  const assetRefsGridApiRef = useGridApiRef();

  const handleEscrowAmountChangeIssue = (
    event: React.ChangeEvent<HTMLInputElement> | Event,
    newValue?: number | number[],
  ) => {
    if (typeof newValue === "number") {
      setEscrowAmount(newValue);
    } else if (event.target) {
      setEscrowAmount(Number((event.target as HTMLInputElement).value));
    }
  };

  const handleEscrowClick = () => {
    if (!escrowAmount || escrowAmount <= 0) {
      setAlertMsgs([...alertMsgs, "Amount to escrow must be positive."]);
      setSeverities([...severities, "warning"]);
    } else if (
      !escrowRemoteRecipientAndNetwork ||
      !escrowRemoteRecipientAndNetwork.recipient ||
      !escrowRemoteRecipientAndNetwork.network
    ) {
      setAlertMsgs([...alertMsgs, "Please provide a recipient."]);
      setSeverities([...severities, "warning"]);
    } else {
      refetchOwnBalance();
      if (!ownBalance) {
        setAlertMsgs([...alertMsgs, "Error fetching own balance."]);
        setSeverities([...severities, "error"]);
      } else if (ownBalance.Amount < escrowAmount) {
        setAlertMsgs([
          ...alertMsgs,
          "Own balance (" +
            ownBalance.Amount +
            ") not large enough to escrow " +
            escrowAmount +
            " wNOK.",
        ]);
        setSeverities([...severities, "warning"]);
      } else {
        console.log(
          "handleEscrowClick event with: " +
            escrowAmount +
            " " +
            escrowRemoteRecipientAndNetwork.recipient +
            " @ " +
            escrowRemoteRecipientAndNetwork.network,
        );

        escrowWnok({
          amount: escrowAmount,
          remoteRecipient: escrowRemoteRecipientAndNetwork.recipient,
          remoteNetwork: escrowRemoteRecipientAndNetwork.network,
        }).then((res) => handleCompleteEscrow(res));
      }
    }
  };

  const handleBridgeOutClick = () => {
    if (assetRefsGridApiRef.current) {
      const selectedRowIds = assetRefsGridApiRef.current.getSelectedRows();

      if (selectedRowIds.size === 1) {
        var bridgeOutAssetRef = selectedRowIds.get(
          selectedRowIds.keys().next().value,
        );

        if (bridgeOutAssetRef) {
          console.log(
            "handleBridgeOutClick event with: " + bridgeOutAssetRef.id,
          );

          bridgeOut({
            assetRefId: bridgeOutAssetRef.id,
            amount: bridgeOutAssetRef.Amount,
            remoteRecipient: bridgeOutAssetRef.RemoteRecipient,
            remoteNetwork: bridgeOutAssetRef.RemoteNetwork ?? "besu", // FIXME: RemoteNetwork needs to be added to utxo code
          }).then((res) => handleCompleteBridgeOut(res));
        }
      } else {
        setAlertMsgs([
          ...alertMsgs,
          "Please bridge out exactly one AssetRef at a time.",
        ]);
        setSeverities([...severities, "warning"]);
      }
    }
  };

  const handleCompleteEscrow = (res: any) => {
    refetchOwnBalance();
    if (res.error) {
      console.log("Error during escrow operation: " + res.error.data);
      setAlertMsgs([
        ...alertMsgs,
        "Error during escrow operation. " + res.error.data,
      ]);
      setSeverities([...severities, "error"]);
    } else {
      setAlertMsgs([...alertMsgs, "Successful Escrow"]);
      setSeverities([...severities, "success"]);
    }
  };

  const handleCompleteBridgeOut = (res: any) => {
    getAssetRefs();
    if (res.error) {
      console.log("Error during bridge out: " + res.error.data);
      setAlertMsgs([
        ...alertMsgs,
        "Error during bridge out: " + res.error.data,
      ]);
      setSeverities([...severities, "error"]);
    } else {
      setAlertMsgs([...alertMsgs, "Successful Bridge Out"]);
      setSeverities([...severities, "success"]);
    }
  };

  if (isUninitializedAssetRefsTable) {
    getAssetRefs();
  }

  const handleGetAssetRefsClick = () => {
    getAssetRefs();
  };

  return (
    <div className="content-area">
      {alerts}

      <h2>Current balance</h2>
      {BalancesTable()}
      <h2>Escrow Tokens</h2>
      <Stack direction="column">
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <SelectNetworkDropdownMenu
              onSelect={setEscrowRemoteRecipientAndNetwork}
              placeholder="Select a recipient"
            />
          </Grid>
          <Grid item xs>
            <TokenInputSlider
              value={escrowAmount}
              onChange={handleEscrowAmountChangeIssue}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              loading={isLoadingEscrowWnok}
              variant="text"
              onClick={handleEscrowClick}
              style={{ height: 40 }}
            >
              Escrow
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
      <h2>Bridge Out Tokens</h2>
      {OwnAssetRefsTable(assetRefsData, assetRefsGridApiRef)}
      <LoadingButton
        loading={isLoadingGetAssetRefs}
        variant="text"
        onClick={handleGetAssetRefsClick}
        style={{ height: 40 }}
      >
        Refresh
      </LoadingButton>
      <LoadingButton
        loading={isLoadingBridgeOut}
        variant="text"
        onClick={handleBridgeOutClick}
        style={{ height: 40 }}
      >
        Bridge Out
      </LoadingButton>
    </div>
  );
};

export default BridgePage;
