//import components
import BankAccordion from "../components/BankAccordion";

//banks page
const BanksPage = () => {
  return (
    <div className="content-area">
      <h2>Registered banks</h2>
      <div style={{ width: "524px", margin: "auto" }}>{BankAccordion()}</div>
    </div>
  );
};

export default BanksPage;
