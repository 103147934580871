import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import * as kc from "../services/Keycloak";
import { Middleware, MiddlewareAPI } from "redux";
import { isRejected } from "@reduxjs/toolkit";
import { setAlert } from "./globalSlice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { keyCloakSecured } from "../components/Environment";

dayjs.extend(utc);

export interface Status {
  FabricNetworkAlive: string;
  OwnMSPID: string;
}
export interface BackendStatus {
  BackendAlive: string;
  DatabaseAlive: string;
}

export interface Balance {
  AccountNo: string;
  Amount: number;
  Timestamp: string;
}

interface LegalEntity {
  legalName: string;
  accountNo: string;
  contactPerson: string;
  eMail: string;
  LEI: string;
}

export interface Transaction {
  Operation: string;
  AccountNoFrom: string;
  AccountNoTo: string;
  Amount: number;
  txId: string;
  Timestamp: string;
  Status: string;

  AccountNoFromUserFriendlyName: string;
  AccountNoToUserFriendlyName: string;
  OperationUserFriendlyName: string;
  Counterparty: string;

  OwnAccountNo: string;
  OwnBalance: number;
}

export interface DocumentMetadata {
  DocumentID: string;
  DocumentName: string;
  Timestamp: string;
  Issuer: string;
  SharedWith: string;
  DocumentSize: number;
}

export interface ZippedDownloadDocumentMetadata {
  DocumentID: string;
  DocumentName: string;
  Issuer: string;
  SharedWith: string;
}

interface Document {
  DocumentID: string;
  DataIsEncoded: boolean;
  Data: string;
}

interface AssetRef {
  TokenType: string;
  AssetRefId: string;
  Owner: string;
  RemoteRecipient: string;
  refwNOKAmount: number;
}

export interface BankName {
  legalName: string;
  fabricName: string;
  utxoName: string;
  besuAddress: string;
}

interface Bridge {
  AccountNo: string;
  Amount: number;
}

export const api = createApi({
  baseQuery: fetchBaseQuery({
    // Fill in your own server starting URL here
    baseUrl: "api",
    prepareHeaders: async (headers) => {
      headers.set("Content-Type", "application/json");
      if (keyCloakSecured) {
        let token = await kc.GetAccessToken();
        headers.set("Authorization", "Bearer " + token);
      }
    },
  }),
  endpoints: (build) => ({
    getStatus: build.query<Status, void>({
      query: () => ({
        url: "wnok-network-info/getFabricStatus",
      }),
    }),

    getBackendStatus: build.query<BackendStatus, void>({
      query: () => ({
        url: "wnok-network-info/getBackendStatus",
      }),
    }),

    getAuditorUtxoNodeStatus: build.query<String, void>({
      query: () => ({
        url: "wnok-network-info/getAuditorUtxoNodeStatus",
      }),
    }),

    getAuditorUtxoNodeReadyStatus: build.query<String, void>({
      query: () => ({
        url: "wnok-network-info/getAuditorUtxoNodeReadyStatus",
      }),
    }),

    getIssuerUtxoNodeStatus: build.query<String, void>({
      query: () => ({
        url: "wnok-network-info/getIssuerUtxoNodeStatus",
      }),
    }),

    getIssuerUtxoNodeReadyStatus: build.query<String, void>({
      query: () => ({
        url: "wnok-network-info/getIssuerUtxoNodeReadyStatus",
      }),
    }),

    getBankUtxoNodeStatus: build.query<String, void>({
      query: () => ({
        url: "wnok-network-info/getBankUtxoNodeStatus",
      }),
    }),

    getBankUtxoNodeReadyStatus: build.query<String, void>({
      query: () => ({
        url: "wnok-network-info/getBankUtxoNodeReadyStatus",
      }),
    }),

    getOwnBalance: build.query<Balance, void>({
      query: () => ({
        url: "account-monitoring/getOwnBalance",
        transformResponse: (rawResult: { result: Balance }) => {
          return rawResult.result;
        },
      }),
    }),

    getOwnBalanceAtDate: build.mutation<Balance, string | void>({
      query: (date = dayjs.utc().format()) => ({
        url: "account-monitoring/getOwnBalanceAtDate",
        params: {
          date: date,
        },
        transformResponse: (rawResult: { result: Balance }) => {
          return rawResult.result;
        },
        method: "POST",
      }),
    }),

    getTotalWnokInNetwork: build.query<{ Amount: number }, void>({
      query: () => ({
        url: "wnok-network-info/getTotalWNOKInNetwork",
      }),
    }),

    getOwnBalanceHistory: build.mutation<
      { balances: Array<Balance> },
      { tFrom: string; tTo: string }
    >({
      query: (body) => ({
        url: "account-monitoring/getOwnBalanceHistory",
        params: {
          dateIntervalFrom: body.tFrom,
          dateIntervalTo: body.tTo,
        },
        method: "POST",
      }),
    }),

    getTotalWNOKHistory: build.mutation<
      { balances: Array<Balance> },
      { tFrom: string; tTo: string }
    >({
      query: (body) => ({
        url: "oversight/getTotalWNOKHistory",
        params: {
          dateIntervalFrom: body.tFrom,
          dateIntervalTo: body.tTo,
        },
        method: "POST",
      }),
    }),

    getTotalTransferVolumeHistory: build.mutation<
      { balances: Array<Balance> },
      { tFrom: string; tTo: string }
    >({
      query: (body) => ({
        url: "oversight/getTotalTransferVolumeHistory",
        params: {
          dateIntervalFrom: body.tFrom,
          dateIntervalTo: body.tTo,
        },
        method: "POST",
      }),
    }),

    getAllAccountBalances: build.query<Array<Balance>, void>({
      query: () => ({
        url: "oversight/getBalancesForAllParticipantsInNetwork",
      }),
    }),

    getAllLegalEntitiesData: build.query<Array<LegalEntity>, void>({
      query: () => ({
        url: "static-data/getAllLegalEntitiesData",
      }),
    }),

    getBankEntitiesData: build.query<Array<LegalEntity>, void>({
      query: () => ({
        url: "static-data/getBankEntitiesData",
      }),
    }),

    getBridgeEntitiesData: build.query<Array<Bridge>, void>({
      query: () => ({
        url: "static-data/getBridgeEntitiesData",
      }),
    }),

    getBankNameMapping: build.query<Array<BankName>, void>({
      query: () => {
        return { url: "static-data/getBankNameMappingData" };
      },
    }),

    getBankNameMappingExcludingSelf: build.query<Array<BankName>, void>({
      query: () => {
        return { url: "static-data/getBankNameMappingDataExcludingSelf" };
      },
    }),

    getOwnBankNameMapping: build.query<Array<BankName>, void>({
      query: () => {
        return { url: "static-data/getOwnBankNameMappingData" };
      },
    }),

    getTransactionHistory: build.mutation<
      { transactions: Array<Transaction> },
      { tFrom: string; tTo: string }
    >({
      query: (body) => ({
        url: "account-monitoring/getOwnTransactionHistory",
        params: {
          dateIntervalFrom: body.tFrom,
          dateIntervalTo: body.tTo,
        },
        method: "POST",
      }),
    }),

    getTransactionHistoryForAllParticipantsInNetwork: build.mutation<
      { transactions: Array<Transaction> },
      { tFrom: string; tTo: string }
    >({
      query: (body) => ({
        url: "oversight/getTransactionHistoryForAllParticipantsInNetwork",
        params: {
          dateIntervalFrom: body.tFrom,
          dateIntervalTo: body.tTo,
        },
        method: "POST",
      }),
    }),

    mintWNOK: build.mutation<
      Balance,
      { amount: number; targetAccountNo: string }
    >({
      query: (body) => ({
        url: "wnok-supply/mintWNOK",
        method: "POST",
        body: {
          Amount: body.amount,
          AccountNo: body.targetAccountNo,
        },
      }),
    }),

    burnWNOK: build.mutation<
      Balance,
      { amount: number; targetAccountNo: string }
    >({
      query: (body) => ({
        url: "wnok-supply/burnWNOK",
        method: "POST",
        body: {
          Amount: body.amount,
          AccountNo: body.targetAccountNo,
        },
      }),
    }),

    sendWNOK: build.mutation<
      Balance,
      { amount: number; targetAccountNo: string }
    >({
      query: (body) => ({
        url: "transaction/sendWNOK",
        method: "POST",
        body: {
          Amount: body.amount,
          AccountNo: body.targetAccountNo,
        },
      }),
    }),

    sendDocument: build.mutation<
      void,
      {
        targetAccountNo: string;
        documentMetadata: string;
        documentData: string;
      }
    >({
      query: (body) => ({
        url: "document/sendDocument",
        method: "POST",
        body: {
          TargetAccountNo: body.targetAccountNo,
          DocumentMetadata: body.documentMetadata,
          DocumentData: body.documentData,
        },
      }),
    }),

    getDocumentMetadataByTimeRange: build.mutation<
      { documents: Array<DocumentMetadata> },
      { accountNo: string; tFrom: string; tTo: string }
    >({
      query: (body) => ({
        url: "document/getDocumentMetadataByTimeRange",
        params: {
          AccountNo: body.accountNo,
          dateIntervalFrom: body.tFrom,
          dateIntervalTo: body.tTo,
        },
        method: "POST",
      }),
    }),

    getDocument: build.mutation<
      Document,
      { issuer: string; sharedWithAccount: string; documentID: string }
    >({
      query: (body) => ({
        url: "document/getDocument",
        method: "POST",
        params: {
          Issuer: body.issuer,
          SharedWithAccount: body.sharedWithAccount,
          DocumentID: body.documentID,
        },
      }),
    }),

    getZippedDocuments: build.mutation<
      string,
      Array<ZippedDownloadDocumentMetadata>
    >({
      query: (documents) => ({
        url: "document/getZippedDocuments",
        params: {
          documents: JSON.stringify(documents),
        },
        method: "POST",
      }),
    }),

    getEvents: build.query<Array<String>, void>({
      query: () => ({
        url: "event/getEvents",
      }),
    }),

    getAssetRefs: build.mutation<Array<AssetRef>, void>({
      query: () => ({
        url: "bridge/getOwnAssetRefs",
      }),
    }),

    escrowWNOK: build.mutation<
      void,
      { amount: number; remoteRecipient: string; remoteNetwork: string }
    >({
      query: (body) => ({
        url: "bridge/escrowWNOK",
        method: "POST",
        body: {
          amount: body.amount,
          remoteRecipient: body.remoteRecipient,
          remoteNetwork: body.remoteNetwork,
        },
      }),
    }),

    bridgeOutWNOK: build.mutation<
      void,
      {
        assetRefId: string;
        amount: number;
        remoteRecipient: string;
        remoteNetwork: string;
      }
    >({
      query: (body) => ({
        url: "bridge/bridgeOutWNOK",
        method: "POST",
        body: {
          assetRefId: body.assetRefId,
          amount: body.amount,
          remoteRecipient: body.remoteRecipient,
          remoteNetwork: body.remoteNetwork,
        },
      }),
    }),

    getBridgeStatus: build.query<String, void>({
      query: () => ({
        url: "bridge/getStatus",
      }),
    }),
  }),
});

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // isRejectedWithValue Or isRejected
    if (isRejected(action)) {
      console.error(action, "MIDDLEWARE"); // get all data from rejected request

      api.dispatch(
        setAlert({
          severity: "error",
          message: `API error: ${action.error.message}`,
        }),
      );
    }

    return next(action);
  };

export const {
  useGetStatusQuery,
  useGetBackendStatusQuery,
  useGetOwnBalanceQuery,
  useGetOwnBalanceAtDateMutation,
  useGetTotalWnokInNetworkQuery,
  useGetOwnBalanceHistoryMutation,
  useGetTotalWNOKHistoryMutation,
  useGetTotalTransferVolumeHistoryMutation,
  useGetAllAccountBalancesQuery,
  useGetAllLegalEntitiesDataQuery,
  useGetBankEntitiesDataQuery,
  useGetBridgeEntitiesDataQuery,
  useGetBankNameMappingQuery,
  useGetBankNameMappingExcludingSelfQuery,
  useGetOwnBankNameMappingQuery,
  useGetTransactionHistoryMutation,
  useGetTransactionHistoryForAllParticipantsInNetworkMutation,
  useSendWNOKMutation,
  useMintWNOKMutation,
  useBurnWNOKMutation,
  useSendDocumentMutation,
  useGetDocumentMetadataByTimeRangeMutation,
  useGetDocumentMutation,
  useGetZippedDocumentsMutation,
  useGetAssetRefsMutation,
  useEscrowWNOKMutation,
  useBridgeOutWNOKMutation,
  useGetEventsQuery,
  useGetAuditorUtxoNodeStatusQuery,
  useGetAuditorUtxoNodeReadyStatusQuery,
  useGetIssuerUtxoNodeStatusQuery,
  useGetIssuerUtxoNodeReadyStatusQuery,
  useGetBankUtxoNodeStatusQuery,
  useGetBankUtxoNodeReadyStatusQuery,
  useGetBridgeStatusQuery,
} = api;
