import * as React from "react";
import Stack from "@mui/material/Stack";
import TokenInputSlider from "../components/TokenInputSlider";
import BalancesTable from "../components/BalancesTable";
import {
  useBurnWNOKMutation,
  useGetOwnBalanceQuery,
  useMintWNOKMutation,
} from "../reducers/apiSlice";
import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { createAlerts } from "../utils/helpers";

const MintBurnPage = () => {
  const [mint, { isLoading: isLoadingMint }] = useMintWNOKMutation();
  const [burn, { isLoading: isLoadingBurn }] = useBurnWNOKMutation();
  var ownAccountNo = "";

  const [targetAmountMint, setTargetAmountMint] = React.useState<number>(300);
  const [targetAmountBurn, setTargetAmountBurn] = React.useState<number>(300);
  const [alertMsgs, setAlertMsgs] = React.useState<string[]>([]);
  const [severities, setSeverities] = React.useState<
    ("error" | "warning" | "info" | "success")[]
  >([]);
  let alerts = createAlerts(alertMsgs, severities);

  const { data: ownBalance, refetch: refetchOwnBalance } =
    useGetOwnBalanceQuery();

  const handleAmountChangeMint = (
    event: React.ChangeEvent<HTMLInputElement> | Event,
    newValue?: number | number[],
  ) => {
    if (typeof newValue === "number") {
      setTargetAmountMint(newValue);
    } else if (event.target) {
      setTargetAmountMint(Number((event.target as HTMLInputElement).value));
    }
  };

  const handleAmountChangeBurn = (
    event: React.ChangeEvent<HTMLInputElement> | Event,
    newValue?: number | number[],
  ) => {
    if (typeof newValue === "number") {
      setTargetAmountBurn(newValue);
    } else if (event.target) {
      setTargetAmountBurn(Number((event.target as HTMLInputElement).value));
    }
  };

  function Mint() {
    if (targetAmountMint) {
      console.log("MintClick event with: " + targetAmountMint);
      mint({
        amount: targetAmountMint,
        targetAccountNo: ownAccountNo,
      }).then((res) => handleComplete(res, "Mint", targetAmountMint));
    } else {
      console.log(
        "Mint button was pressed, but could not retrieve an amount value to be minted.",
      );
    }
  }

  function Burn() {
    if (targetAmountBurn) {
      refetchOwnBalance();
      if (!ownBalance) {
        setAlertMsgs([...alertMsgs, "Error fetching own balance."]);
        setSeverities([...severities, "error"]);
      } else if (ownBalance.Amount < targetAmountBurn) {
        setAlertMsgs([
          ...alertMsgs,
          "Own balance (" +
            ownBalance.Amount +
            ") not large enough to burn " +
            targetAmountBurn +
            " wNOK.",
        ]);
        setSeverities([...severities, "warning"]);
      } else {
        console.log("BurnClick event with: " + targetAmountBurn);
        burn({
          amount: targetAmountBurn,
          targetAccountNo: ownAccountNo,
        }).then((res) => handleComplete(res, "Burn", targetAmountBurn));
      }
    } else {
      console.log(
        "Burn button was pressed, but could not retrieve an amount value to be burned.",
      );
    }
  }

  const handleComplete = (res: any, action: string, amount: number) => {
    refetchOwnBalance();
    if (res.error) {
      console.log("Error during: " + action + ". " + res.error.data);
      setAlertMsgs([
        ...alertMsgs,
        "Error during: " + action + ". " + res.error.data,
      ]);
      setSeverities([...severities, "error"]);
    } else {
      setAlertMsgs([
        ...alertMsgs,
        "Successful: " + action + " (" + amount + ")",
      ]);
      setSeverities([...severities, "success"]);
    }
  };

  return (
    <div className="content-area">
      {alerts}

      <h2>Current balance</h2>
      {BalancesTable()}

      <h2>Actions</h2>
      <Stack direction="column" spacing={1}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <TokenInputSlider
              value={targetAmountMint}
              onChange={handleAmountChangeMint}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              loading={isLoadingMint}
              variant="text"
              onClick={Mint}
              style={{ height: 40 }}
            >
              Mint
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <TokenInputSlider
              value={targetAmountBurn}
              onChange={handleAmountChangeBurn}
            />
          </Grid>
          <Grid item>
            <LoadingButton
              loading={isLoadingBurn}
              variant="text"
              onClick={Burn}
              style={{ height: 40 }}
            >
              Burn
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default MintBurnPage;
