// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  scrollbar-gutter: stable;
}

.draw-area {
  width: 80%;
  max-width: 1000px;
  margin: auto;
}

.content-area {
  width: 96%;
  margin: auto;
}

h1 {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}

h2 {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
}

h3 {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

h4 {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,iCAAiC;EACjC,eAAe;AACjB;;AAEA;EACE,iCAAiC;EACjC,eAAe;AACjB;;AAEA;EACE,iCAAiC;EACjC,eAAe;AACjB;;AAEA;EACE,iCAAiC;EACjC,eAAe;AACjB","sourcesContent":["html {\n  scrollbar-gutter: stable;\n}\n\n.draw-area {\n  width: 80%;\n  max-width: 1000px;\n  margin: auto;\n}\n\n.content-area {\n  width: 96%;\n  margin: auto;\n}\n\nh1 {\n  font-family: \"Roboto\", sans-serif;\n  font-size: 20px;\n}\n\nh2 {\n  font-family: \"Roboto\", sans-serif;\n  font-size: 16px;\n}\n\nh3 {\n  font-family: \"Roboto\", sans-serif;\n  font-size: 14px;\n}\n\nh4 {\n  font-family: \"Roboto\", sans-serif;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
