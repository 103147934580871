import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import { useGetBankNameMappingQuery } from "../reducers/apiSlice";
import {
  EMPTY_VALUE,
  formattedOperation,
} from "../services/TransactionTableFormat";
import { transformAccountTransactions } from "../utils/helpers";

import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(localizedFormat);

export default function BasicTable(transactions: Array<any> | undefined) {
  const { data: bankNameMapping } = useGetBankNameMappingQuery();
  if (!transactions) return <>Loading</>;

  const transformedTransactions = transformAccountTransactions(
    transactions,
    bankNameMapping,
  );
  if (!transformedTransactions) return <>Loading</>;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Operation</TableCell>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transformedTransactions.length === 0 ? (
            <TableRow>
              {" "}
              No transactions found in the given time range.{" "}
            </TableRow>
          ) : (
            transformedTransactions.map((transaction) => (
              <TableRow
                key={transaction.Timestamp}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {dayjs(transaction.Timestamp).local().format("L LT")}
                </TableCell>
                <TableCell>{formattedOperation(transaction)}</TableCell>
                <TableCell>
                  {transaction.AccountNoFromUserFriendlyName || EMPTY_VALUE}
                </TableCell>
                <TableCell>
                  {transaction.AccountNoToUserFriendlyName || EMPTY_VALUE}
                </TableCell>
                <TableCell>{transaction.Amount ?? EMPTY_VALUE}</TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
