import { red, pink, green } from "@mui/material/colors";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  useGetStatusQuery,
  useGetBackendStatusQuery,
  useGetBankUtxoNodeStatusQuery,
  useGetIssuerUtxoNodeStatusQuery,
  useGetAuditorUtxoNodeStatusQuery,
  useGetBridgeStatusQuery,
} from "../reducers/apiSlice";
import { cbEnabled } from "./Environment";
import { skipToken } from "@reduxjs/toolkit/query";

function statusIcon(alive: string) {
  switch (alive) {
    case "ok":
      return <CheckCircleOutlineOutlinedIcon sx={{ color: green[500] }} />;
    case "YES":
      return <CheckCircleOutlineOutlinedIcon sx={{ color: green[500] }} />;
    case "NO":
      return <ErrorOutlineOutlinedIcon sx={{ color: red[500] }} />;
    default:
      return <HelpOutlineOutlinedIcon sx={{ color: pink[500] }} />;
  }
}

export default function StatusIndicator() {
  const defaultParams = {
    pollingInterval: 30000,
  };
  const { data: status } = useGetStatusQuery(undefined, defaultParams);
  const { data: auditorUtxoNode } = useGetAuditorUtxoNodeStatusQuery(
    undefined,
    defaultParams,
  );
  const { data: bankUtxoNode } = useGetBankUtxoNodeStatusQuery(
    undefined,
    defaultParams,
  );
  const { data: backendStatus } = useGetBackendStatusQuery(
    undefined,
    defaultParams,
  );
  const { data: issuerUtxoNode } = useGetIssuerUtxoNodeStatusQuery(
    cbEnabled ? undefined : skipToken,
    defaultParams,
  );
  const { data: bridgeStatus } = useGetBridgeStatusQuery(
    undefined,
    defaultParams,
  );

  if (!status) {
    return <p>Loading</p>;
  }

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 160,
        bgcolor: "background.paper",
        boxShadow: 1,
        borderRadius: 1,
      }}
    >
      <ListItem>
        <ListItemText
          primary="Backend"
          secondary={"Alive: " + backendStatus?.BackendAlive ?? null}
        />
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "background.paper" }}>
            {statusIcon(backendStatus?.BackendAlive ?? "NO")}
          </Avatar>
        </ListItemAvatar>
      </ListItem>

      <ListItem>
        <ListItemText
          primary="Fabric"
          secondary={"Alive: " + status.FabricNetworkAlive}
        />
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "background.paper" }}>
            {statusIcon(status.FabricNetworkAlive)}
          </Avatar>
        </ListItemAvatar>
      </ListItem>

      <ListItem>
        <ListItemText
          primary="DB"
          secondary={"Alive: " + backendStatus?.DatabaseAlive ?? null}
        />
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "background.paper" }}>
            {statusIcon(backendStatus?.DatabaseAlive ?? "NO")}
          </Avatar>
        </ListItemAvatar>
      </ListItem>

      <ListItem>
        <ListItemText
          primary="Bank UTXO"
          secondary={"Alive: " + bankUtxoNode ?? null}
        />
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "background.paper" }}>
            {statusIcon(bankUtxoNode?.toString() ?? "NO")}
          </Avatar>
        </ListItemAvatar>
      </ListItem>

      <ListItem>
        <ListItemText
          primary="Auditor UTXO"
          secondary={"Alive: " + auditorUtxoNode ?? null}
        />
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "background.paper" }}>
            {statusIcon(auditorUtxoNode?.toString() ?? "NO")}
          </Avatar>
        </ListItemAvatar>
      </ListItem>

      {cbEnabled && (
        <>
          <ListItem>
            <ListItemText
              primary="Issuer UTXO"
              secondary={"Alive: " + issuerUtxoNode ?? null}
            />
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: "background.paper" }}>
                {statusIcon(issuerUtxoNode?.toString() ?? "NO")}
              </Avatar>
            </ListItemAvatar>
          </ListItem>
        </>
      )}

      <ListItem>
        <ListItemText
          primary="Bridge"
          secondary={"Alive: " + bridgeStatus ?? null}
        />
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: "background.paper" }}>
            {statusIcon(bridgeStatus?.toString() ?? "NO")}
          </Avatar>
        </ListItemAvatar>
      </ListItem>

      <ListItem>
        <ListItemText primary="MSP ID" secondary={status.OwnMSPID} />
      </ListItem>
    </List>
  );
}
