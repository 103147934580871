import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import { useGetBankNameMappingExcludingSelfQuery } from "../reducers/apiSlice";

interface NamesListProps {
  onSelect: (value: string) => void;
  bankNameType: "fabricName" | "utxoName" | "besuAddress";
  placeholder: string;
}

type OptionType = {
  value: string;
  label: string;
};

const DropdownMenu: React.FC<NamesListProps> = ({
  onSelect,
  bankNameType,
  placeholder,
}) => {
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<OptionType>>(null);

  const { data: bankNameMapping, isLoading } =
    useGetBankNameMappingExcludingSelfQuery();

  if (isLoading || !bankNameMapping) return <>Loading...</>;

  const options: OptionType[] = bankNameMapping.map((bankName: any) => ({
    value: bankName[bankNameType],
    label: bankName.legalName,
  }));

  const handleChange = (option: SingleValue<OptionType>) => {
    setSelectedOption(option);
    onSelect(option?.value ?? "");
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleChange}
      options={options}
      styles={{
        container: (provided, state) => ({
          ...provided,
          width: 280,
          fontFamily: "Open Sans, sans-serif",
        }),
        control: (provided) => ({
          ...provided,
          height: 40,
          minHeight: 40,
        }),
      }}
      placeholder={placeholder}
    />
  );
};

export default DropdownMenu;
