import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import DocumentsTable from "../components/DocumentsTable";
import { useFilePicker } from "use-file-picker";
import HistoryRangePicker from "../components/HistoryRangePicker";
import {
  useSendDocumentMutation,
  useGetDocumentMetadataByTimeRangeMutation,
  useGetDocumentMutation,
  useGetZippedDocumentsMutation,
  useGetBankNameMappingQuery,
} from "../reducers/apiSlice";
import { LoadingButton } from "@mui/lab";
import { useGridApiRef, GridValidRowModel } from "@mui/x-data-grid";
import { Box, Grid } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import SelectBankDropdownMenu from "../components/SelectBankDropdownMenu";
import { createAlerts } from "../utils/helpers";

let utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
const maximumDocumentSize = 10000000; // in bytes

const DocumentsPage = () => {
  const [targetAccountNo, setTargetAccountNo] = React.useState<string>("");
  const [viewDocumentsAccount, setViewDocumentAccount] =
    React.useState<string>("");
  const [alertMsgs, setAlertMsgs] = React.useState<string[]>([]);
  const [severities, setSeverities] = React.useState<
    ("error" | "warning" | "info" | "success")[]
  >([]);
  let alerts = createAlerts(alertMsgs, severities);
  const [sendDocument, { isLoading: isLoadingSendDocument }] =
    useSendDocumentMutation();

  const [tFrom, setTFrom] = React.useState<Dayjs | null>(
    dayjs.utc().subtract(24, "hours"),
  );
  const [tTo, setTTo] = React.useState<Dayjs | null>(dayjs.utc());
  const [
    updateDocumentsTimeRange,
    { isLoading: isLoadingUpdateDocumentsTimeRange, data: documentsListData },
  ] = useGetDocumentMetadataByTimeRangeMutation();

  const documentGridApiRef = useGridApiRef();
  const [
    getDocument,
    { isLoading: isLoadingGetDocument, data: downloadedDocumentData },
  ] = useGetDocumentMutation();
  const [getZippedDocuments, { data: downloadedZippedDocumentsData }] =
    useGetZippedDocumentsMutation();
  const [documentMetadata, setDocumentMetadata] =
    React.useState<GridValidRowModel>();

  const {
    openFilePicker,
    filesContent,
    loading: isLoadingLoadDocument,
  } = useFilePicker({
    multiple: false,
    readAs: "BinaryString",
  });

  const [newDocumentIsReadyForDownload, setNewDocumentIsReadyForDownload] =
    React.useState<boolean>(false);
  const [
    newZippedDocumentsAreReadyForDownload,
    setNewZippedDocumentsAreReadyForDownload,
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    function downloadData(
      data: string,
      dataIsEncoded: boolean,
      documentName: string,
    ) {
      const blob = new Blob([dataIsEncoded ? decode(data) : data]);
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = documentName;
      a.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      });
    }

    if (newDocumentIsReadyForDownload) {
      if (!downloadedDocumentData || !documentMetadata) {
        setAlertMsgs([...alertMsgs, "Error downloading file."]);
        setSeverities([...severities, "error"]);
      } else {
        downloadData(
          downloadedDocumentData.Data,
          downloadedDocumentData.DataIsEncoded,
          documentMetadata.DocumentName,
        );
        setNewDocumentIsReadyForDownload(false);
      }
    } else if (newZippedDocumentsAreReadyForDownload) {
      if (!downloadedZippedDocumentsData) {
        setAlertMsgs([...alertMsgs, "Error downloading file."]);
        setSeverities([...severities, "error"]);
      } else {
        downloadData(downloadedZippedDocumentsData, true, "documents.zip");
        setNewZippedDocumentsAreReadyForDownload(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentMetadata,
    downloadedDocumentData,
    newDocumentIsReadyForDownload,
    downloadedZippedDocumentsData,
    newZippedDocumentsAreReadyForDownload,
  ]);

  function checkDocumentSize(documentSize: number): boolean {
    if (documentSize === 0) {
      setAlertMsgs([...alertMsgs, "Document can't be empty."]);
      setSeverities([...severities, "warning"]);
      return false;
    } else if (documentSize >= maximumDocumentSize) {
      setAlertMsgs([
        ...alertMsgs,
        "Document size must be less than " +
          (maximumDocumentSize / 1000000).toString() +
          " MB.",
      ]);
      setSeverities([...severities, "warning"]);
      return false;
    } else {
      return true;
    }
  }

  function getStringSize(data: string): number {
    return new Blob([data]).size;
  }

  function decode(base64data: string) {
    let byteString = atob(base64data);
    let byteStringLength = byteString.length;
    let bytes = new Uint8Array(byteStringLength);
    for (let i = 0; i < byteStringLength; i++) {
      bytes[i] = byteString.charCodeAt(i);
    }
    return bytes;
  }

  function handleTimeRangeUpdateClick() {
    if (viewDocumentsAccount === "") {
      setAlertMsgs([
        ...alertMsgs,
        "Please select an account for which to view shared documents.",
      ]);
      setSeverities([...severities, "warning"]);
    } else if (tFrom && tTo) {
      updateDocumentsTimeRange({
        accountNo: viewDocumentsAccount,
        tFrom: tFrom.second(0).format(),
        tTo: tTo.second(59).format(),
      });
    }
  }

  const handleSendDocumentComplete = (res: any) => {
    if (res.error) {
      console.log("Error during file send " + res.error.data);
      setAlertMsgs([...alertMsgs, "Error during file send " + res.error.data]);
      setSeverities([...severities, "error"]);
    } else {
      setAlertMsgs([...alertMsgs, "Successfully shared file."]);
      setSeverities([...severities, "success"]);
    }
  };

  const handleSendDocumentClick = () => {
    if (filesContent.length === 0) {
      setAlertMsgs([...alertMsgs, "Please load a document first."]);
      setSeverities([...severities, "warning"]);
    } else if (!targetAccountNo) {
      setAlertMsgs([
        ...alertMsgs,
        "Please select a recipient for the document.",
      ]);
      setSeverities([...severities, "warning"]);
    } else if (targetAccountNo) {
      let documentName = filesContent[0].name;
      let documentData = filesContent[0].content;
      let documentSize = getStringSize(documentData);

      if (checkDocumentSize(documentSize) === true) {
        // check if file name ends matches certain file endings to decide whether to encode it
        const re = /.(txt|json)$/;
        let documentIsEncoded = re.exec(documentName) === null;

        sendDocument({
          targetAccountNo: targetAccountNo,
          documentMetadata: JSON.stringify({
            DocumentName: documentName,
            DataIsEncoded: documentIsEncoded,
          }),
          documentData: JSON.stringify(
            documentIsEncoded ? btoa(documentData) : documentData,
          ),
        }).then((res: any) => handleSendDocumentComplete(res));
      }
    }
  };

  const handleDownloadDocumentsClick = () => {
    if (documentGridApiRef.current) {
      const selectedRowIds = documentGridApiRef.current.getSelectedRows();

      if (selectedRowIds.size === 1) {
        let doc1 = selectedRowIds.get(selectedRowIds.keys().next().value);
        if (doc1) {
          setDocumentMetadata(doc1);
          getDocument({
            issuer: doc1.Issuer,
            sharedWithAccount: doc1.SharedWith,
            documentID: doc1.id,
          }).then(() => setNewDocumentIsReadyForDownload(true));
        }
      } else {
        let documents = [];
        for (let doc of selectedRowIds) {
          documents.push({
            DocumentID: doc[1].id,
            DocumentName: doc[1].DocumentName,
            Issuer: doc[1].Issuer,
            SharedWith: doc[1].SharedWith,
          });
        }
        getZippedDocuments(documents).then(() =>
          setNewZippedDocumentsAreReadyForDownload(true),
        );
      }
    }
  };
  const { data: bankNameMapping } = useGetBankNameMappingQuery();

  return (
    <div className="content-area">
      {alerts}

      <h2>Send document</h2>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <LoadingButton
            variant="text"
            loading={isLoadingLoadDocument}
            onClick={openFilePicker}
            style={{ height: 40 }}
          >
            Browse files
          </LoadingButton>
        </Grid>
        <Grid item>
          <TextField
            label="Selected file"
            variant="outlined"
            size="small"
            sx={{ minWidth: 150 }}
            inputProps={{ readOnly: true }}
            value={filesContent.length > 0 ? filesContent[0].name : ""}
            style={{ height: 40 }}
          />
        </Grid>
        <Grid item>
          <SelectBankDropdownMenu
            onSelect={setTargetAccountNo}
            bankNameType="fabricName"
            placeholder="Select a recipient"
          />
        </Grid>
        <Grid item>
          <LoadingButton
            loading={isLoadingLoadDocument || isLoadingSendDocument}
            variant="text"
            onClick={handleSendDocumentClick}
            style={{ height: 40 }}
          >
            Send
          </LoadingButton>
        </Grid>
      </Grid>

      <h2 style={{ marginBottom: "5px" }}>View documents</h2>
      <SelectBankDropdownMenu
        onSelect={setViewDocumentAccount}
        bankNameType="fabricName"
        placeholder="Select documents shared with"
      />
      <Box
        style={{ marginTop: "10px" }}
        boxShadow={1}
        bgcolor="background.paper"
        borderRadius={1}
        p={2}
      >
        <Stack spacing={2} direction="row" width="100%" alignItems="center">
          {HistoryRangePicker(tFrom, setTFrom, tTo, setTTo)}
          <LoadingButton
            loading={isLoadingUpdateDocumentsTimeRange}
            variant="text"
            onClick={handleTimeRangeUpdateClick}
          >
            Update
          </LoadingButton>
        </Stack>
      </Box>

      <p />
      {DocumentsTable(
        documentsListData?.documents,
        documentGridApiRef,
        bankNameMapping,
      )}
      <LoadingButton
        loading={isLoadingGetDocument}
        variant="text"
        onClick={handleDownloadDocumentsClick}
      >
        Download
      </LoadingButton>
    </div>
  );
};

export default DocumentsPage;
