import { BankName, Transaction, Balance } from "../reducers/apiSlice";
import AlertMsg from "../components/Alert";
import { ownUtxoAccount } from "../components/Environment";
import dayjs, { Dayjs } from "dayjs";

let localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

/**
 * Map strings from API interface for Transaction.Operation to enum.
 * The string values provided are those returned by the API.
 * To instantiate an enum from string, cast: `myString as TransactionType`
 */
export enum TransactionType {
  Transfer = "TransferwNOK",
  Escrow = "Escrow",
  ReleaseEscrow = "ReleaseEscrow",
  Mint = "Mint",
  Burn = "Burn",
}

export function transformAccount(
  account: Balance | undefined,
  bankNameMapping: BankName[] | undefined,
): Balance | undefined {
  if (!account || !bankNameMapping) return account;

  const bankNameMap: { [key: string]: string } = {};
  bankNameMapping.forEach((bankName) => {
    bankNameMap[bankName.utxoName] = bankName.legalName;
  });

  return {
    ...account,
    AccountNo: bankNameMap[account.AccountNo] || account.AccountNo,
  };
}

export function transformAccountTransactions(
  transactions: Transaction[] | undefined,
  bankNameMapping: BankName[] | undefined,
): Transaction[] | undefined {
  if (!transactions || !bankNameMapping) return transactions;

  // Map utxo name to display name (legal name)
  const bankNameMap: { [key: string]: string } = {};
  bankNameMapping.forEach((bankName) => {
    bankNameMap[bankName.utxoName] = bankName.legalName;
  });

  // Map TransactionType to display name
  const operationDisplayNameMap: { [key in TransactionType]: string } = {
    [TransactionType.Transfer]: "Transfer",
    [TransactionType.ReleaseEscrow]: "Escrow Release",
    [TransactionType.Escrow]: "Escrow",
    [TransactionType.Mint]: "Mint",
    [TransactionType.Burn]: "Burn",
  };

  // Determine the counter party for the given transaction
  function getCounterparty(transaction: Transaction): string {
    if (transaction.AccountNoFrom === ownUtxoAccount) {
      return bankNameMap[transaction.AccountNoTo] || transaction.AccountNoTo;
    }
    if (transaction.AccountNoTo === ownUtxoAccount) {
      return (
        bankNameMap[transaction.AccountNoFrom] || transaction.AccountNoFrom
      );
    }
    return "--";
  }

  return transactions.map((transaction) => ({
    ...transaction,
    AccountNoFromUserFriendlyName:
      bankNameMap[transaction.AccountNoFrom] || transaction.AccountNoFrom,
    AccountNoToUserFriendlyName:
      bankNameMap[transaction.AccountNoTo] || transaction.AccountNoTo,
    OperationUserFriendlyName:
      operationDisplayNameMap[transaction.Operation as TransactionType] ||
      transaction.Operation,
    Counterparty: getCounterparty(transaction),
  }));
}

export function transformAccountBalances(
  balances: Balance[] | undefined,
  bankNameMapping: BankName[] | undefined,
): Balance[] | undefined {
  if (!balances || !bankNameMapping) return balances;

  const bankNameMap: { [key: string]: string } = {};
  bankNameMapping.forEach((bankName) => {
    bankNameMap[bankName.utxoName] = bankName.legalName;
  });

  return balances.map((balance) => ({
    ...balance,
    AccountNo: bankNameMap[balance.AccountNo] || balance.AccountNo,
  }));
}

export function createAlerts(
  alertMsgs: string[],
  severities: ("error" | "warning" | "info" | "success")[],
) {
  let alerts = [];
  for (let i = 0; i < alertMsgs.length; i++) {
    alerts.push(
      <AlertMsg message={alertMsgs[i]} severity={severities[i]} alertkey={i} />,
    );
  }
  return alerts;
}

export function getTimeStepForSmoothing(tDiff: number) {
  let tStep = 1000; // 1 second

  if (tDiff > 30 * 1000 && tDiff <= 5 * 60 * 1000) {
    tStep = 10 * 1000; // 10 seconds
  } else if (tDiff <= 15 * 60 * 1000) {
    tStep = 30 * 1000; // 30 seconds
  } else if (tDiff <= 30 * 60 * 1000) {
    tStep = 1 * 60 * 1000; // 1 minute
  } else if (tDiff <= 150 * 60 * 1000) {
    tStep = 5 * 60 * 1000; // 5 minutes
  } else if (tDiff <= 5 * 60 * 60 * 1000) {
    tStep = 10 * 60 * 1000; // 10 minutes
  } else if (tDiff <= 15 * 60 * 60 * 1000) {
    tStep = 30 * 60 * 1000; // 30 minutes
  } else if (tDiff <= 2 * 24 * 60 * 60 * 1000) {
    tStep = 1 * 60 * 60 * 1000; // 1 hour
  } else if (tDiff <= 7 * 24 * 60 * 60 * 1000) {
    tStep = 4 * 60 * 60 * 1000; // 4 hours
  } else if (tDiff <= 31 * 24 * 60 * 60 * 1000) {
    tStep = 24 * 60 * 60 * 1000; // 1 day
  } else if (tDiff <= 26 * 7 * 24 * 60 * 60 * 1000) {
    tStep = 7 * 24 * 60 * 60 * 1000; // 7 days
  } else if (tDiff <= 2 * 365 * 24 * 60 * 60 * 1000) {
    tStep = 30 * 24 * 60 * 60 * 1000; // 30 days
  } else if (tDiff <= 10 * 365 * 24 * 60 * 60 * 1000) {
    tStep = 3 * 30 * 24 * 60 * 60 * 1000; // 3 months
  } else if (tDiff <= 30 * 365 * 24 * 60 * 60 * 1000) {
    tStep = 365 * 24 * 60 * 60 * 1000; // 1 year
  } else {
    tStep = 10 * 365 * 24 * 60 * 60 * 1000; // 10 years
  }

  return tStep;
}

export function getSmoothedTimeSeries(
  data: Array<any>,
  tFrom: Dayjs,
  tTo: Dayjs,
  amountAtTTo: number,
) {
  let times = data.map((datum) => dayjs(datum.Timestamp).local()).reverse();
  let amounts = data.map((datum) => datum.Amount).reverse();

  let tDiff = tTo.diff(tFrom);

  const tStep = getTimeStepForSmoothing(tDiff);

  let aggregatedTimes = [];
  let aggregatedAmounts = [];

  let t = tFrom;
  let i = 0;

  let aggregatedAmount;
  if (amounts.length === 0) {
    aggregatedAmount = amountAtTTo;
  } else {
    aggregatedAmount = amounts[0];
  }

  while (t.isBefore(tTo)) {
    while (i < times.length && times[i].isBefore(t.add(tStep))) {
      aggregatedAmount = amounts[i];
      i += 1;
    }

    aggregatedAmounts.push(aggregatedAmount);
    aggregatedTimes.push(t.toDate());
    t = t.add(tStep);
  }

  return { times: aggregatedTimes, amounts: aggregatedAmounts };
}

export function getAggregatedTimeSeries(
  data: Array<any>,
  tFrom: Dayjs,
  tTo: Dayjs,
) {
  let times = data.map((datum) => dayjs(datum.Timestamp).local()).reverse();
  let amounts = data.map((datum) => datum.Amount).reverse();

  let tDiff = tTo.diff(tFrom);

  const tStep = getTimeStepForSmoothing(tDiff);

  let aggregatedTimes = [];
  let aggregatedAmounts = [];

  let t = tFrom;
  let i = 0;

  while (t.isBefore(tTo)) {
    let aggregatedAmount = 0;
    while (i < times.length && times[i].isBefore(t.add(tStep))) {
      aggregatedAmount += amounts[i];
      i += 1;
    }

    aggregatedAmounts.push(aggregatedAmount);
    aggregatedTimes.push(t.toDate());
    t = t.add(tStep);
  }

  return { times: aggregatedTimes, amounts: aggregatedAmounts };
}
