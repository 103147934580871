import * as React from "react";
import { Snackbar, Alert, AlertTitle } from "@mui/material";

interface AlertProps {
  message: string;
  severity: "error" | "warning" | "info" | "success";
  alertkey: number;
}

export default function AlertMsg({ message, severity, alertkey }: AlertProps) {
  const [open, setOpen] = React.useState(true);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  let alertTitle = severity.charAt(0).toUpperCase() + severity.slice(1);
  let pos = 100 * (alertkey % 5);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <div style={{ position: "relative", top: pos, left: -20, zIndex: 999 }}>
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          <AlertTitle>{alertTitle}</AlertTitle>
          {message}
        </Alert>
      </div>
    </Snackbar>
  );
}
