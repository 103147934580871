import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import { Stack, TextField } from "@mui/material";

export const defaultSliderValue = 300;
let maxValue = 1000;

interface TokenInputSliderProps {
  value: number;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement> | Event,
    newValue?: number | number[],
  ) => void;
}

export default function TokenInputSlider({
  value,
  onChange,
}: TokenInputSliderProps) {
  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      onChange(event, newValue);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  const handleBlur = () => {
    if (value < 0) {
      onChange({} as React.ChangeEvent<HTMLInputElement>, 0);
    } else if (value > maxValue) {
      onChange({} as React.ChangeEvent<HTMLInputElement>, maxValue);
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Typography>wNOK:</Typography>
      </Grid>
      <Grid item xs>
        <Stack>
          <Slider
            value={typeof value === "number" ? value : 0}
            max={maxValue}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
          />
        </Stack>
      </Grid>
      <Grid item>
        <TextField
          value={value}
          onChange={handleInputChange}
          onBlur={handleBlur}
          inputProps={{
            step: maxValue / 10,
            min: 0,
            max: maxValue,
            type: "number",
            "aria-labelledby": "input-slider",
          }}
          style={{ width: 80 }}
          size="small"
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
}
