import Keycloak from "keycloak-js";
import { keyCloakSecured } from "../components/Environment";

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM || "realm",
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID || "clientId",
});

if (keyCloakSecured) {
  try {
    console.log(
      "Authenticating to keycloak server, realm, clientId: " +
        process.env.REACT_APP_KEYCLOAK_URL +
        ", " +
        process.env.REACT_APP_KEYCLOAK_REALM +
        ", " +
        process.env.REACT_APP_KEYCLOAK_CLIENTID,
    );
    const authenticated = await keycloak.init({
      onLoad: "login-required",
      checkLoginIframe: false,
    });
    console.log(
      `User is ${authenticated ? "authenticated" : "not authenticated"}`,
    );
  } catch (error) {
    console.error("Failed to initialize adapter:", error);
  }
} else {
  // Keycloak authentication is disabled, proceed without authentication.
  console.log("Keycloak authentication is disabled. Proceeding without it.");
}

export function Activate() {
  if (keyCloakSecured) {
    console.log("KeyCloak is active");
  } else {
    console.log("KeyCloak is NOT active");
  }
}

export function Logout() {
  if (keyCloakSecured) {
    console.log("Logging out of keycloak");
    keycloak.logout();
  } else {
    console.log("Keycloak disabled -- no real logout");
  }
}

export async function GetAccessToken() {
  if (keyCloakSecured) {
    // Check the token is valid before continuing
    await keycloak
      .updateToken(5)
      .then(function (refreshed) {
        if (refreshed) {
          console.log("Token was successfully refreshed");
        } else {
          console.log("Token is still valid");
        }
      })
      .catch(function () {
        console.log("Failed to refresh the token, or the session has expired");
      });

    return keycloak.token;
  } else {
    return null;
  }
}
