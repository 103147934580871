import StatusIndicator from "../components/StatusIndicator";

const InfoPage = () => {
  return (
    <div className="content-area">
      <div>
        <h2>Status</h2>
        {StatusIndicator()}
      </div>
    </div>
  );
};

export default InfoPage;
