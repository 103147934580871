import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useGetAllLegalEntitiesDataQuery } from "../reducers/apiSlice";

export const AllParticipants = () => {
  const { data: allLegalEntities } = useGetAllLegalEntitiesDataQuery();

  if (!allLegalEntities) return <>Loading</>;

  const allNames = allLegalEntities.map(
    (legalEntity: any) => legalEntity.legalName,
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Account Number</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allNames.map((legalName) => {
            return (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {legalName}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
