import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  useGetTotalWnokInNetworkQuery,
  useGetAllLegalEntitiesDataQuery,
} from "../reducers/apiSlice";

export default function BasicTable() {
  const { data } = useGetTotalWnokInNetworkQuery(undefined, {
    pollingInterval: 7000,
  });
  const { data: allLegalEntities } = useGetAllLegalEntitiesDataQuery();

  if (!allLegalEntities) return <>Loading</>;

  const allNames = allLegalEntities.map(
    (legalEntity: any) => legalEntity.legalName,
  );

  const numberOfParticipants = allNames.length;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell align="right">Statistics</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key={data?.Amount}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              Total wNOK in Network
            </TableCell>
            <TableCell align="right">{data?.Amount ?? "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Total Number of Participants in Network
            </TableCell>
            <TableCell align="right">{numberOfParticipants}</TableCell>
          </TableRow>
          {/* End of Mock Row */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
