import { LineChart } from "@mui/x-charts/LineChart";
import dayjs, { Dayjs } from "dayjs";
import { getAggregatedTimeSeries } from "../utils/helpers";

var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default function BasicVolumeChart(
  balances: Array<any> | undefined,
  tFrom: Dayjs | null,
  tTo: Dayjs | null,
) {
  if (!balances || !tFrom || !tTo) return <>Loading</>;

  const aggregatedBalances = getAggregatedTimeSeries(balances, tFrom, tTo);

  var minBalance = 0;
  var maxBalance = 0;
  if (aggregatedBalances.amounts.length > 0) {
    minBalance = Math.min(...aggregatedBalances.amounts);
    maxBalance = Math.max(...aggregatedBalances.amounts);
  }
  if (minBalance === maxBalance) {
    maxBalance = maxBalance + 10;
  }
  var diffBalance = maxBalance - minBalance;
  minBalance = Math.max(0, minBalance - 0.05 * diffBalance);
  maxBalance = maxBalance + 0.05 * diffBalance;

  return (
    <LineChart
      xAxis={[
        {
          data: aggregatedBalances.times,
          scaleType: "time",
          min: tFrom.toDate(),
          max: tTo.toDate(),
        },
      ]}
      yAxis={[
        {
          min: minBalance,
          max: maxBalance,
        },
      ]}
      series={[
        {
          data: aggregatedBalances.amounts,
          curve: "step",
        },
      ]}
      height={300}
    />
  );
}
