//imports for accordion menu
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
//icon for accordion menu
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
//to customize style
import { styled } from "@mui/material/styles";
//helper to fill space
import Box from "@mui/material/Box";

import {
  useGetBankEntitiesDataQuery,
  useGetBankNameMappingQuery,
} from "../reducers/apiSlice";

//Custom Syting of MUI Accordion
//Custom Accordion Style
const Accordion = styled((props: AccordionProps) => (
  //text stays when box opens
  <MuiAccordion disableGutters {...props} />
))(({ theme }) => ({
  //solid line border
  border: `1px solid ${theme.palette.divider}`,
  // remove terminations between stacked accordions
  "&:not(:last-child)": { borderBottom: 0 },
  "&:before": { display: "none" },
}));

//Custom AccordionSummary Style
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  // define arrow icon and size
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // place icon on left side
  flexDirection: "row-reverse",
  // rotate icon by 90 not 180 degerees
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  // margin between icon and text
  "& .MuiAccordionSummary-content": { marginLeft: theme.spacing(1) },
}));

function AccordionItem(data: any) {
  return (
    <Accordion key={data.legalName}>
      <AccordionSummary>
        <Typography align="left"> {data.legalName} </Typography>
        {/* move bank balance to the right using boxes */}
        <Box style={{ marginLeft: "auto" }} justifyContent="flex-end">
          <Typography> {data.bank_balance} </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <table key={data.legalName} style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>Name</td>
              <td align="right">{data.legalName}</td>
            </tr>
            <tr>
              <td>Account number</td>
              <td align="right">{data.accountNo}</td>
            </tr>
            <tr>
              <td>Contact person</td>
              <td align="right">{data.contactPerson}</td>
            </tr>
            <tr>
              <td>E-Mail</td>
              <td align="right">{data.eMail}</td>
            </tr>
            <tr>
              <td>LEI</td>
              <td align="right">{data.LEI}</td>
            </tr>
            <tr>
              <td>UTXO Name</td>
              <td align="right">{data.utxoName}</td>
            </tr>
            <tr>
              <td>Besu Address</td>
              <td align="right">{data.besuAddress}</td>
            </tr>
          </tbody>
        </table>
      </AccordionDetails>
    </Accordion>
  );
}

//function to build accordion menu
export default function BankAccordion() {
  const data = useGetBankEntitiesDataQuery().data;
  const mappingData = useGetBankNameMappingQuery().data;
  if (!data || !mappingData || mappingData.length === 0 || data.length === 0)
    return <>Loading</>;

  var fullAccordion: any[] = [];
  data.forEach(function (item) {
    let d = {
      legalName: item.legalName,
      accountNo: item.accountNo,
      contactPerson: item.contactPerson,
      eMail: item.eMail,
      LEI: item.LEI,
      utxoName: mappingData.find((x: any) => x.legalName === item.legalName)
        ?.utxoName,
      besuAddress: mappingData.find((x: any) => x.legalName === item.legalName)
        ?.besuAddress,
    };

    fullAccordion.push(AccordionItem(d));
  });

  return fullAccordion;
}
