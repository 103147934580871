import { Transaction } from "../reducers/apiSlice";
import { ownUtxoAccount } from "../components/Environment";
import { TransactionType } from "../utils/helpers";

// Common formatting for transaction tables

/**
 * This string is displayed as a placeholder for undisplayable values
 */
export const EMPTY_VALUE = "--";

/**
 * Returns a string containing the user friendly operation name (if available),
 * and if applicable, the "Confirmed" status
 */
export function formattedOperation(transaction: Transaction): string {
  const operation =
    transaction.OperationUserFriendlyName ?? transaction.Operation;
  const status =
    transaction.Status === "Confirmed" ? "" : ` (${transaction.Status})`;
  return `${operation}${status}`;
}

/**
 * Returns the signed transaction amount or a placeholder.
 */
export function formattedAmount(transaction: Transaction): string {
  if (!transaction.Amount) {
    return EMPTY_VALUE;
  }
  if (transaction.Operation === TransactionType.Burn) {
    return String(-1 * transaction.Amount);
  }
  if (
    [TransactionType.Escrow, TransactionType.Transfer].includes(
      transaction.Operation as TransactionType,
    ) &&
    transaction.AccountNoFrom === ownUtxoAccount
  ) {
    return String(-1 * transaction.Amount);
  }
  return String(transaction.Amount);
}
