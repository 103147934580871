export const cbEnabled = process.env.REACT_APP_CB_FEATURES_ENABLED === "yes";

export const keyCloakSecured =
  process.env.REACT_APP_KEY_CLOAK_SECURED === "yes";

export const orgLogoPath = process.env.REACT_APP_ORG_LOGO_PATH;

export const ownUtxoAccount = process.env.REACT_APP_KEY_OWN_UTXO_ACCOUNT;

export const orgIconPath = process.env.REACT_APP_ORG_ICON_PATH;

export const tabName = process.env.REACT_APP_TAB_NAME;
